let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "MCCALCO LTD",
    "role": "Software development contracting services based in Manchester",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/callum-mcclean-0a4762148/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"email",
          "url":"mailto:callum@mccalco.com",
          "className":"fa fa-envelope"
        }
      ],
    "aboutme":"MCCALCO provides full stack developer consultancy and contracting services based in Manchester. Specialising in projects using Amazon Web Services, serverless deployment technologies and infrastructure as code. MCCALCO has proven experience building cloud based platforms using cutting edge technologies and agile development methodologies. MCCALCO holds an AWS Certified Solutions Architect Associate qualification.",
    "address":"Manchester",
    "website":"callum@mccalco.com",
    "skillsDescription":"Our skills include",
    "skills":[
      {
        "skillname":"NodeJs"
      },
      {
        "skillname":"ReactJs"
      },
      {
        "skillname":"AWS"
      },
      {
        "skillname":"Serverless"
      },
      {
        "skillname":"Terraform"
      },
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"TypeScript"
      },
      {
        "skillname":"C#"
      },
      {
        "skillname":"Git"
      },
      {
        "skillname":"Jenkins"
      },
      {
        "skillname":"GraphQL"
      },
      {
        "skillname":"SQL"
      },
      {
        "skillname":"NoSQL"
      },
      {
        "skillname":"Jira"
      },
      {
        "skillname":"HTML"
      },
      {
        "skillname":"CSS"
      }
    ],
    "testimonials":[
      {
        "description":"We were provided with an end to end solution. The combination of extensive knowledge, efficiency and keeping us well informed at all times sets this company apart. The teams forward thinking, design led approach surpassed all of our expectations and the end result was fantastic.",
        "name":"Puji Furniture"
      }
    ]
  }
  
  export default resumeData