import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip'

export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

         <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Skills</span></h1>
            </div>

            <div className="nine columns main-col">

   				<div>

   				   <div className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <Chip label={item.skillname} className="chip" size="medium" />
                    )
                  })
                }

   					</div>

   				</div>

   			</div>

         </div>

      </section>
    );
  }
}